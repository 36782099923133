'use strict'
// 跨域请求的地址
var domain = '/api'

// 服务端地址
//var target = 'http://localhost:9090/'
//var target = 'http://192.168.120.154:8080/'
// 正式环境
var target = 'https://apivol.shuahuo.cn/'
// 正式环境
var socketUrl = 'ws://47.94.160.9:9696/ws'
//var socketUrl = 'ws://localhost:9696/ws'

//前端页面的地址
var fontUrl = 'https://vol.shuahuo.cn/'

module.exports = {
  domain: domain,
  target: target,
  fontUrl:fontUrl,
  socketUrl: socketUrl
}
